import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import { RouterProvider } from 'react-router-dom';
import { router } from "./router"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<div>loading.....</div>}>
        <RouterProvider router={router}></RouterProvider>
        {/* <App /> */}
    </Suspense>
);

