import React from "react"
import './foods.css'

const img_list = [
    'https://cdn.img.tyhjrbl.com/img/202401/17/1730engkf0cm24.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/17311knmp1zn4m.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1731ncj2dbfrtv.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1731vw8lp4y0t2.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1731j7kwevts5q.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1731jgs36da6ci.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1732r54s6us73w.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1732gatf9hqsqn.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1732iyjmxi5fy2.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1733vltrrwgcyj.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1733kdux65n5ny.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1733bqw0ufp1qn.png'
]


export default function Foods () {
    return (
        <>
            <div className='foo_box'>
                <div className='one'>
                    {img_list.slice(0, 3).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
                <div className='two'>
                    {img_list.slice(3, 6).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
                <div className='three'>
                    {img_list.slice(6, 8).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='four'>
                    {img_list.slice(8 , 10).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='five'>
                    {img_list.slice(10 , 12).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}