import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Movie from "../pages/movie/movie.jsx";
import Music from "../pages/foods/foods.jsx";
import Life from "../pages/life/life.jsx";
import Foods from "../pages/foods/foods.jsx";
import Coupon from "../pages/coupon/coupon.jsx";


const App = lazy(() => import("../App"))
const Index = lazy(() => import("../view/index/index.jsx"))
const Product = lazy(() => import("../view/product/product.jsx"))
const Scheme = lazy(() => import("../view/scheme/scheme.jsx"))
const Cooperation = lazy(() => import("../view/cooperation/cooperation.jsx"))
const We = lazy(() => import("../view/we/we.jsx"))
const Prot = lazy(() => import("../view/prot/prot.jsx"))


// const Movie = lazy(() => import("../pages/movie/movie.jsx"))
// const Music = lazy(() => import("../pages/music/music.jsx"))
// const Life = lazy(() => import("../pages/life/life.jsx"))
// const Foods = lazy(() => import("../pages/foods/foods.jsx"))
// const Coupon = lazy(() => import("../pages/coupon/coupon.jsx"))

const List = [
    {
        path:'/',
        element: <App></App>,
        // loader:(data) => {
        //     return data
        // },
        children:[
            //首页
            {
                index: true,
                element:<Index></Index>
            },
            //产品
            {
                path:'product',
                element: <Product></Product>,
                children: [
                    {
                        index:true,
                        element:<Movie></Movie>
                    },
                    {
                        path:'music',
                        element:<Music></Music>
                    },
                    {
                        path:'life',
                        element:<Life></Life>
                    },
                    {
                        path:'foods',
                        element:<Foods></Foods>
                    },
                    {
                        path:'coupon',
                        element:<Coupon></Coupon>
                    },
                ]
            },
            //方案
            {
                path:'scheme',
                element:<Scheme></Scheme>
            },
            //合作
            {
                path:'cooperation',
                element:<Cooperation></Cooperation>,
            },
            //我们
            {
                path:'we',
                element:<We></We>
            },
            //接口文档
            {
                path:'prot',
                element:<Prot></Prot>
            }
    ]
    }
]

export const router = createBrowserRouter(List)