import React from "react"
import './life.css'

const img_list = [
    'https://cdn.img.tyhjrbl.com/img/202401/17/1720irvo4cxsq1.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1720yzw268glu8.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1721i9l0cg493m.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/17215a5ny6vx4a.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1721maviomkogp.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1721b648fzvdbz.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1722wba778vgmd.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1722alxn5enrtl.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1722mr7wqpvsas.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1722tul4tcto2g.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1722qzvsagc9oh.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1723md6o9exxcg.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1723g6fvxodf12.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1723akb4csl49k.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1723jqhdn6yxjc.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1724j62v4xywtd.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1724q11dn8qx79.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1724rgbjuury3m.png'
]


export default function Life () {
    return (
        <>
            <div className='life_box'>
                <div className='one'>
                    {img_list.slice(0, 4).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='two'>
                    {img_list.slice(4, 8).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='three'>
                    {img_list.slice(8, 12).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='four'>
                    {img_list.slice(12 , 15).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
                <div className='five'>
                    {img_list.slice(15 , 18).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
            </div>
        </>
    )
}