import React from "react"
import './movie.css'

const img_list = [
    'https://cdn.img.tyhjrbl.com/img/202401/17/1524dg97ytspyg.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1528k3t7s493hr.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1528mr6dxmkv5l.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1529ps78ykxvx1.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/15300xa8rmemot.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1530ce98zroykt.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/15306b1hqcs5b5.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1617epxijk5413.png',     
    'https://cdn.img.tyhjrbl.com/img/202401/17/1532lmdn7vqmb7.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1532bju9py0l09.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/15338belrt3zsi.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1533ne9187clrf.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1533cdiweutxfi.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/153403boyite4z.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/15348es1ubzetp.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1534u5v25e75rv.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/15347zbhgp8l72.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1535w3r7g5yjrv.png',
]


export default function Movie () {
    return (
        <>
            <div className='mov_box'>
                <div className='one'>
                    {img_list.slice(0, 4).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='two'>
                    {img_list.slice(4, 8).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='three'>
                    {img_list.slice(8, 12).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                </div>
                <div className='four'>
                    {img_list.slice(12 , 15).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
                <div className='five'>
                    {img_list.slice(15 , 18).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                </div>
            </div>
        </>
    )
}