import React from "react"
import './coupon.css'

const img_list = [
    'https://cdn.img.tyhjrbl.com/img/202401/17/1738mrdiv5pa63.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1739ozi36t5c7r.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1739gerrami95c.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1739vkn2wxrf2p.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1739po89i8ybqu.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1739k6g25fsvsm.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1740t6l9tl0exm.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1740cafenpm2t9.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/1740o2fyei4b20.png',
    'https://cdn.img.tyhjrbl.com/img/202401/17/17405v22wznapp.png'
]


export default function Coupon () {
    return (
        <>
            <div className='cou_box'>
                <div className='one'>
                    {img_list.slice(0, 2).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='two'>
                    {img_list.slice(2, 4).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='three'>
                    {img_list.slice(4, 6).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='four'>
                    {img_list.slice(6 , 8).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
                <div className='five'>
                    {img_list.slice(8 , 10).map((value, index) => {
                        return (
                            <div key={index}>
                                <img src={value} alt="" />
                            </div>
                    )})}
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}